/**
 *   @include viewport-unit(height, 50vh);
 *   @include viewport-unit(font-size, 5vmin);
 */
@use 'sass:math';
@mixin viewport-unit($property, $value) {
  #{$property}: $value;

  $unit: unit($value);

  @if (index((vw, vh, vmin, vmax), $unit) != null) {
    $devices: (
        (768px, 1024px), // iPad (all versions)
        (320px, 480px),  // iPhone 4
        (320px, 568px)   // iPhone 5, 5C, 5S
    );

    @each $device in $devices {
      $device-width: nth($device, 1);
      $device-height: nth($device, 2);

      $device-query: "only screen and (-webkit-min-device-pixel-ratio: 1)";
      $device-query: "#{$device-query} and (device-width: #{$device-width})";
      $device-query: "#{$device-query} and (device-height: #{$device-height})";

      $percent: math.div($value , ($value * 0 + 1));// $value / ($value * 0 + 1); // see https://github.com/sass/sass/issues/533

      $percent-width: calc($device-width * $percent / 100);
      $percent-height: calc($device-height * $percent / 100);

      @if ($unit == vmin or $unit == vmax) {
        @media #{$device-query} {
          #{$property}: if($unit == vmin, $percent-width, $percent-height);
        }
      }
      @else {
        @media #{$device-query} and (orientation: portrait) {
          #{$property}: if($unit == vw, $percent-width, $percent-height);
        }

        @media #{$device-query} and (orientation: landscape) {
          #{$property}: if($unit == vw, $percent-height, $percent-width);
        }
      }
    }
  }
}
