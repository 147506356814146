/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@use "sass:meta";
/* Import from project */
@use "_variables.scss" as *;
@use "mixins.scss" as *;
/* Core CSS required for Ionic components to work properly */
@use "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


// App Global Sass

@font-face {
  font-family: "PTSansv12";
  src: local("PTSansv12"), url(./assets/fonts/pt-sans-v12-latin-regular.ttf) format("truetype");
}

// Ionic global variables
:root {
  --ion-font-family: #{meta.inspect($default-font-family)};
  --font-size: #{$default-font-size};
  --padding-top: 0;
  .original {
    --default-dark-text-color: #{$default-dark-text-color};
    --default-text-color: #{$default-text-color};
    --default-dark-gray: #{$default-dark-gray};
    --default-accent-color: #{$default-accent-color};
    --ion-text-color: #{$default-dark-text-color};
    --ion-text-color-rgb: #{$default-dark-text-color-rgb};
    --ion-background-color: #{$default-background-color};
    --ion-background-color-rgb: #{$default-background-color-rgb};
    --ion-backdrop-opacity: #{$default-backdrop-opacity};

    --ion-color-secondary: #F8BF96;
    --ion-color-secondary-rgb: 248,191,150;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #daa884;
    --ion-color-secondary-tint: #f9c5a1;

    --ion-color-favorite: #F5822F;
    --ion-color-favorite-rgb: 245,130,47;
    --ion-color-favorite-contrast: #000;
    --ion-color-favorite-contrast-rgb: 0, 0, 0;
    --ion-color-favorite-shade: #d87229;
    --ion-color-favorite-tint: #f68f44;
  }

  .bw-pos {
    --default-dark-text-color: #{$bw-pos-dark-text-color};
    --default-text-color: #{$black};
    --default-dark-gray: #{$bw-pos-dark-gray};
    --default-accent-color: #{$bw-pos-accent-color};
    --ion-text-color: #{$black};
    --ion-text-color-rgb: #{$black-rgb};
    --ion-background-color: #{$white};
    --ion-background-color-rgb: #{$white-rgb};
    --ion-backdrop-opacity: #{$bw-pos-backdrop-opacity};
    --ion-color-primary: #{$black};

    --ion-color-secondary: #eee;
    --ion-color-secondary-rgb: 238,238,238;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #daa884;
    --ion-color-secondary-tint: #f9c5a1;

    --ion-color-favorite: #{$white};
    --ion-color-favorite-rgb: #{$white-rgb};
    --ion-color-favorite-contrast: #000;
    --ion-color-favorite-contrast-rgb: 0, 0, 0;
    --ion-color-favorite-shade: #{$white};
    --ion-color-favorite-tint: #{$white};
  }

  .bw-neg {
    --default-dark-text-color: #{$bw-neg-dark-text-color};
    --default-text-color: #{$bw-neg-text-color};
    --default-dark-gray: #{$black};
    --default-accent-color: #{$black};
    --ion-text-color: #{$bw-neg-dark-text-color};
    --ion-text-color-rgb: #{$bw-neg-dark-text-color-rgb};
    --ion-background-color: #{$black};
    --ion-background-color-rgb: #{$black-rgb};
    --ion-backdrop-opacity: #{$bw-neg-backdrop-opacity};
    --ion-color-primary: #{$white};

    --ion-color-secondary: #333;
    --ion-color-secondary-rgb: 238,238,238;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #daa884;
    --ion-color-secondary-tint: #f9c5a1;
  }
  .by-pos {
    --default-dark-text-color: #{$by-pos-dark-text-color};
    --default-text-color: #{$black};
    --default-dark-gray: #{$yellow};
    --default-accent-color: #{$yellow};
    --ion-text-color: #{$black};
    --ion-text-color-rgb: #{$black-rgb};
    --ion-background-color: #{$yellow-light};
    --ion-background-color-rgb: #{$yellow-rgb};
    --ion-backdrop-opacity: #{$by-pos-backdrop-opacity};
    --ion-color-primary: #{$black};

    --ion-color-secondary: #ff9;
    --ion-color-secondary-rgb: 255,255,153;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #daa884;
    --ion-color-secondary-tint: #f9c5a1;
  }
  .by-neg {
    --default-dark-text-color: #{$yellow};
    --default-text-color: #{$yellow};
    --default-dark-gray: #{$black};
    --default-accent-color: #{$by-neg-accent-color};
    --ion-text-color: #{$yellow};
    --ion-text-color-rgb: #{$yellow-rgb};
    --ion-background-color: #{$black};
    --ion-background-color-rgb: #{$black-rgb};
    --ion-backdrop-opacity: #{$by-neg-backdrop-opacity};
    --ion-color-primary: #{$yellow};

    --ion-color-secondary: #333;
    --ion-color-secondary-rgb: 238,238,238;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #daa884;
    --ion-color-secondary-tint: #f9c5a1;

    //--ion-color-favorite: #{$black};
    //--ion-color-favorite-rgb: #{$black-rgb};
    //--ion-color-favorite-contrast: #000;
    //--ion-color-favorite-contrast-rgb: 0, 0, 0;
    //--ion-color-favorite-shade: #{$white};
    //--ion-color-favorite-tint: #{$white};
  }

  /*
  --ion-color-primary: #7F7F7F;
  --ion-color-primary-rgb: 191,191,191;
  --ion-color-primary-contrast: #000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #a8a8a8;
  --ion-color-primary-tint: #c5c5c5;

  --ion-color-secondary: #F8BF96;
  --ion-color-secondary-rgb: 248,191,150;
  --ion-color-secondary-contrast: #FFFFFF;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #daa884;
  --ion-color-secondary-tint: #f9c5a1;

  --ion-color-tertiary: #F5822F;
  --ion-color-tertiary-rgb: 245,130,47;
  --ion-color-tertiary-contrast: #FFFFFF;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #d87229;
  --ion-color-tertiary-tint: #f68f44;
  */
  --ion-color-evocare: #F8BF96;
  --ion-color-evocare-rgb: 248,191,150;
  --ion-color-evocare-contrast: #000;
  --ion-color-evocare-contrast-rgb: 0,0,0;
  --ion-color-evocare-shade: #e0b4ba;
  --ion-color-evocare-tint: #fed2d7;



}
.ion-color-evocare {
  --ion-color-base: var(--ion-color-evocare);
  --ion-color-base-rgb: var(--ion-color-evocare-rgb);
  --ion-color-contrast: var(--ion-color-evocare-contrast);
  --ion-color-contrast-rgb: var(--ion-color-evocare-contrast-rgb);
  --ion-color-shade: var(--ion-color-evocare-shade);
  --ion-color-tint: var(--ion-color-evocare-tint);
}
.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}
.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  --ion-color-contrast: var(--ion-color-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-shade);
  --ion-color-tint: var(--ion-color-secondary-tint);
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary);
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-shade);
  --ion-color-tint: var(--ion-color-tertiary-tint);
}

// other

:root {
  font-size: min(max(var(--font-size) * 1px, min(4vw, 4vh)), (var(--font-size) * 1.5) * 1px);
}

html, body {
  // fix for black background in fullscreen
  background-color: var(--ion-background-color);
}

ion-button.btn, .button-native, .button-inner {
  --border-radius: 10px;
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: none;
  --padding-start: 1.3em;
  --padding-end: 1.3em;
  --padding-top: 0;
  --padding-bottom: 0; // .05rem;//.1em;
  font-weight: bold;
  font-size: 1rem;
  text-transform: none;
  --color: var(--ion-text-color); // #000;
  --border-color: var(--ion-text-color); // #000;
  --background: none;
  height: fit-content;
  &:hover {
    opacity: 0.8;
  }
}
ion-button, button-inner, .button-native {
  color: var(--ion-text-color);
  --color: var(--ion-text-color);
  background: none;
  --background: none;
  --background-hover: var(--ion-color-secondary);
    & .real-btn {
      --border-radius: 15px;
      --border-color: var(--ion-text-color);
      --border-style: solid;
      --border-width: 1px;
      text-decoration: underline;
    }
}
ion-button.small-btn {
  height: fit-content !important;
}

ion-button {
  --background: none;
  --background-hover: var(--ion-text-color-step-450);
  --background-activated: var(--ion-text-color-step-450);
  --background-focused: var(--ion-text-color-step-450);

  --color: var(--ion-text-color);

  --border-radius: 15px;
  --border-color: var(--ion-text-color);
  --border-style: solid;
  --border-width: 1px;

}
ion-button.btn-nob {
  --border-width: 0;
}

.by-pos, .by-neg, .bw-pos, .bw-neg {
  ion-button, [role=button] {
    text-decoration: underline;
  }
}

ion-toolbar {
  background: transparent!important;
  --background: transparent!important;
}

ion-content{
  //color: white;
  font-weight: 500;
  background: transparent!important;
  --background: transparent!important;
  &::part(scroll)::-webkit-scrollbar {
    display: none;
  }
}

ion-router-outlet {
  display: grid;
}

ion-header {
  background: transparent;
  --background: transparent;
}
.modal-logout .modal-wrapper.sc-ion-modal-md {
  --width: 90%;
  --height: auto;
}
.modal-change-email .modal-wrapper {
  --width: 90%;
  --height: auto;
}
.modal-change-password .modal-wrapper.sc-ion-modal-md {
  --width: 90%;
  --height: auto;
}

.modal-logout, .modal-change-password, .modal-feedback-complete, .modal-change-email {
  --box-shadow: -0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2) !important;
  --border-radius: 2rem;
  padding: 2rem;
}

.custom-quit {
  width: 4.3rem;
  height: 4.3rem;
  background: url(./assets/img/icon_quit.svg) !important;
  position: absolute;
  top: -.9rem;
  right: -.8rem;
}

.scrollbar-pages {
  height: calc(100% - 4rem) !important;
  [class~="ps__rail-y"] {
    margin-right: 0.5rem;
  }
}

.scrollbar {
  --scrollbar-border-radius: 10px !important;
  --scrollbar-track-color: var(--ion-text-color) !important;
  --scrollbar-thumb-color: var(--ion-color-secondary) !important; //#F8BF96
  --scrollbar-size: .3rem !important;
  --scrollbar-padding: 0;
  --scrollbar-viewport-margin: 50px;
  &.scrollbar-modal {
    --scrollbar-padding: 0px !important;

    .ng-scroll-viewport {
      border: 2rem solid #ffffff;
      border-bottom-width: 4rem;
    }
  }

  &.scrollbar-height-header {
    height: calc(100% - 4rem) !important;
  }
}

ng-scrollbar, .ng-scroll-content {
  max-width: 980px;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  .scrollbar-footer-adjustable {
    .ng-scroll-viewport {
      border-bottom-width: 7rem !important;
    }
    ion-modal.modal-with-scrollbar {
      padding: 0!important;
    }

    .scrollbar.scrollbar-modal .ng-scroll-viewport {
      border-right: .5rem;
      border-left: .5rem;
    }
    .ng-scroll-content {
      max-width: 70%;
      margin: 0 auto;
    }
  }
}

ion-tab-bar {
  height: 4.5rem;
  position: relative;
  --border: 0;
  --background: transparent;

  ion-tab-button {
    display: flex;
    flex-direction: column-reverse;
    font-size: 1rem !important;
    color: var(--ion-text-color); // var(--default-dark-text-color); // #fff;
    border-top: .2em solid #fff;
    position: relative;
    background: var(--default-dark-gray);
    --background-focused: var(--ion-color-secondary);
    --padding-end: .2em;
    --padding-start: .2em;

    &:hover {
      background: var(--default-dark-gray) !important;
      border-color: #D5D5D5;
    }

    &.active, &.active:hover {
      border-color: var(--ion-text-color) //#F5822F;
    }

    ion-label {
      font-weight: bold;
      overflow: visible;
      font-size: 0.7rem;
    }

    span {
      color: #F47920;

      &.badge {
        position: absolute;
        top: .5rem;
        right: .5rem;
        display: inline-block;
        min-width: .4rem;
        padding: .1rem .3rem;
        border-radius: 50%;
        font-size: .5rem;
        text-align: center;
        background: #F47920;
        color: #fefefe;
      }
    }
  }
}

.multi-step-form {
  .form-heading{
    font-weight: bold;
  }
  .form-group, .form-group--flex {
    textarea, input {
      background: var(--ion-background-color) !important;
      border: solid 0.12rem var(--ion-text-color);
      border-radius: 10px;
      width: 85%;
      &:focus {
        border-color: var(--ion-text-color);
      }
    }
    label::before {
      position: relative;
      top: 1rem;
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: rgba(191,191,191,.4);

      &:has(:checked, :active)  {
        background: #d87229;
        border: red solid 10px;
      }

    }
    input[type="radio"] {

      color: transparent !important;
      accent-color: #d87229;
    }
  }
  .full-width {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    background-color: var(--ion-background-color);
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }

  h4 {
    margin-top: 0;
    font-weight: bold;
  }

  .form-group {
    input {
      max-width: 100%;
    }
  }
}

.input {
  --placeholder-color: #7F7F7F;
  --placeholder-opacity: 1;
  border-bottom: solid 0.12rem var(--default-dark-gray); //  #BFBFBF;
  font-size: 1rem;
  --padding-start: 0;
  --padding-bottom: 0;
  --border-radius: 0;
}

.input-dark {
  --placeholder-color: var(--default-dark-text-color); // #FFFFFF;
  --color: var(--default-dark-text-color); // #FFFFFF;
  border-bottom-color: var(--default-dark-text-color);  //#FFFFFF;
}

.button-light {
  --background: var(--ion-color-secondary); // #F8BF96;
}

.header {
  height: 4rem;
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  background: url("assets/img/evocare_grey.svg") top 1.2rem left .8rem / 8rem auto  no-repeat transparent;
  &--pos-abs {
    position: absolute;
    right: 0;
    background: url("assets/img/evocare_grey.svg") top .4rem right .8rem / 8rem auto  no-repeat transparent;
  }
}

.heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--default-text-color); //#7F7F7F;
  display: block;
  margin-left: .3rem;
  margin-top: .3rem;
  &--progress {
    padding-top: 2rem;
  }
  span {
    color: #F47920;
  }
}

button.back {
  height: 37px;
  width: 68px;
  border-radius: 10px;
  border: 1px solid var(--ion-text-color);
  background: var(--ion-background-color); //  #F8BF96
  color: var(--ion-text-color);
  &:hover {
    opacity: 0.8;
  }
}

button.start {
  background: var(--ion-color-secondary) !important; // #F8BF96
  box-shadow: -2px 2px 15px -5px rgb(0 0 0 / 51%);
  border-radius: 10px;
  width: 215px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: var(--default-dark-text-color); // #fff;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
}

.box-container {
  padding: 4px 12px;

  .shadow-box {
    box-shadow: -2px 2px 15px -5px rgba(0,0,0,0.51);
    border-radius: 10px;
    border-color: var(--ion-color-secondary);
    background: var(--ion-color-secondary);
    font-size: 1.1rem;
    padding: .3em 1em;
  }
}

#background-content {
  background: transparent;
}

.toolbar-title {
  font-size: 2.5rem!important;
  text-align: center !important;
  color:  #ED6D18 !important;
  font-weight: 300 !important;
}

//button {
//  background: #ffa84c;
//  background: -moz-linear-gradient(top, #ffa84c 0%, #ff7b0d 100%) !important;
//  background: -webkit-linear-gradient(top, #ffa84c 0%, #ff7b0d 100%) !important;
//  background: linear-gradient(to bottom, #ffa84c 0%, #ff7b0d 100%) !important;
//  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffa84c', endColorstr='#ff7b0d', GradientType=0)
//}

.outline-button {
  background: transparent !important;
  color: #ffa84c !important;
  border: 0 !important;
  box-shadow: white 0 0 0 !important;
  ion-icon {
    color: #ffa84c !important;
  }
}

.toolbar-background {
  background: white !important;
}

.card-md {
  text-overflow: ellipsis;
}

.toast-notification {
  div.toast-wrapper {
    max-width: 1000px;
    div.toast-container {
      height: 8rem;
      div.toast-message {
        font-size: 3rem;
      }
    }
  }
}

.modal-with-scrollbar {
  --box-shadow: -0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2) !important;
  --border-radius: 0.5rem;
  padding: 2rem;
}

.modal-scrollbar {
  .ps-content {
    padding: 2rem 3rem 4rem 2rem;
  }
}
.custom-scrollbar {
  scrollbar-color: var(--ion-color-secondary) var(--default-dark-gray); //  #F8BF96 #BFBFBF;
  scrollbar-width: thin;
  &::-webkit-scrollbar-track
  {
    border-radius: 10px;
    background-color: var(--default-dark-gray); // #BFBFBF;
  }

  &::-webkit-scrollbar
  {
    width: 5px;
    background-color: var(--default-dark-gray); // #BFBFBF;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: var(--ion-color-secondary); // #F8BF96;
  }
}


.modal-button
{
  --box-shadow: -0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
}

.modal-footer {
  position: fixed;
  bottom: 0;
  height: 4rem;
  width: 100%;
}
ion-modal::part(scroll) {
  scroll-y: auto!important;
}


.form-group {
  font-size: 1rem !important;
  label {
    font-size: 1rem !important;
  }
}
.page-diary .form-group .form-group__radio {
  display: inline-block;
  padding: 1em;
}

// === CUSTOM ICONS ===
ion-icon {
  font-size: 1.5rem !important;
  &.ion-icon-large {
    width: 3.5rem;
    height: 3.5rem;
  }
  &[class*="custom-"] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: var(--default-dark-text-color); //currentColor;
    width: 3rem;
    height: 3rem;
  }

  &[class*="custom-audio"] {
    mask-image: url(./assets/evo-icons/evo_audio.svg);
  }

  &[class*="custom-balls"] {
    mask-image: url(./assets/evo-icons/evo_balls.svg);
  }

  &[class*="custom-cancel"] {
    mask-image: url(./assets/evo-icons/evo_cancel.svg);
  }

  &[class*="custom-check"] {
    mask-image: url(./assets/evo-icons/evo_check.svg);
  }
  &[class*="custom-calendar"] {
    mask-image: url(./assets/evo-icons/evo_calendar.svg);
  }
  &[class*="custom-diagramm"] {
    mask-image: url(./assets/evo-icons/evo_diagramm.svg);
  }
  &[class*="custom-door"] {
    mask-image: url(./assets/evo-icons/evo_door_floor.svg);
  }
  &[class*="custom-door-tab"] {
    mask-image: url(./assets/evo-icons/evo_door.svg);
    width: 2em;
    height: 2em;
  }
  &[class*="custom-happy"] {
    mask-image: url(./assets/evo-icons/evo_happy.svg);
  }
  &[class*="custom-therapy"] {
    mask-image: url(./assets/img/icon_menu_therapie.svg);
  }
  &[class*="custom-consultation"] {
    mask-image: url(./assets/img/icon_menu_sprechstunde.svg);
  }
  &[class*="custom-data"] {
    mask-image: url(./assets/img/icon_menu_ihredaten.svg);
  }
  &[class*="custom-event"] {
    mask-image: url(./assets/img/icon_menu_termine.svg);
  }
  &[class*="custom-happy-tab"] {
    mask-image: url(./assets/evo-icons/evo_happy.svg);
    width: 2em;
    height: 2em;
  }
  &[class*="custom-info"] {
    mask-image: url(./assets/evo-icons/evo_info.svg);
  }
  &[class*="custom-letter"] {
    mask-image: url(./assets/evo-icons/evo_letter.svg);
  }
  &[class*="custom-lock"] {
    mask-image: url(./assets/evo-icons/evo_lock.svg);
  }
  &[class*="custom-microphone-outline"] {
    mask-image: url(./assets/evo-icons/evo_microphone_outline.svg);
  }
  &[class*="custom-microphone"] {
    mask-image: url(./assets/evo-icons/evo_microphone.svg);
  }
  &[class*="custom-noticed"] {
    mask-image: url(./assets/evo-icons/evo_noticed.svg);
  }
  &[class*="custom-patients"] {
    mask-image: url(./assets/evo-icons/evo_patients.svg);
  }
  &[class*="custom-phone"] {
    mask-image: url(./assets/evo-icons/evo_phone.svg);
  }
  &[class*="custom-phone-tab"] {
    mask-image: url(./assets/evo-icons/evo_phone.svg);
    width: 2em;
    height: 2em;
  }
  &[class*="custom-play"] {
    mask-image: url(./assets/evo-icons/evo_play.svg);
  }

  &[class*="custom-plus"] {
    mask-image: url(./assets/evo-icons/evo_plus.svg);
  }

  &[class*="custom-print"] {
    mask-image: url(./assets/evo-icons/evo_print.svg);
  }

  &[class*="custom-record"] {
    mask-image: url(./assets/evo-icons/evo_record.svg);
  }

  &[class*="custom-reload"] {
    mask-image: url(./assets/evo-icons/evo_reload.svg);
  }

  &[class*="custom-send"] {
    mask-image: url(./assets/evo-icons/evo_send.svg);
  }
  &[class*="custom-sync"] {
    mask-image: url(./assets/evo-icons/evo_sync.svg);
  }
  &[class*="custom-wifi"] {
    mask-image: url(./assets/evo-icons/Wlan_orange.svg);
  }
}


@media screen and (min-width: 768px) {

  ion-header {
    .custom-quit {
      display: none;
    }
  }
}

@media screen and (max-width: 410px) {

  ion-tab-bar {
    ion-tab-button {
      --padding-end: 0;
      --padding-start: 0;

      ion-label {
        font-size: .7rem;
      }
    }
  }

  .input {
    font-size: 1rem;
  }

  .heading {
    font-size: 1.6rem;
  }
}

ion-tabs {
  position: relative;
  display: grid!important;
}
.preview-text p {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}


.original {
  .main-bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      z-index: -1;
      background: url('assets/img/background2.svg') center /90vw no-repeat #fff;
    }
  }
}
.bw-neg, .by-neg {
  .main-bg {
    background: var(--ion-color-secondary);
  }
}
.by-pos {
  .main-bg {
    background: var(--ion-background-color);
  }
}

@media (max-aspect-ratio: 121 / 80) {

  ion-tabs {
    grid-template-areas:
    "content"
    "mainnav";
    grid-template-rows: minmax(4.5rem,1fr) 4.5rem;
  }
  .box-container {
    max-width: 98vw!important;
  }
  .mydata {
    .box-container {
      .shadow-box {
        margin-bottom: 12px!important;
      }
    }
  }
  .message-control {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .original {
    .main-bg {
      &::before {
        background: url('assets/img/background2.svg') left 50%  bottom -2.5rem / 110% no-repeat #fff;
      }
    }
  }
  .bw-neg, .by-neg {
    .main-bg {
      background: var(--ion-color-secondary);
    }
  }
  .by-pos {
    .main-bg {
      background: var(--ion-background-color);
    }
  }
}

ion-row {
  justify-content: center;
}

@media (min-aspect-ratio: 121 / 80) {
  //Must declare in global in order to work with translations (Login.Title)
  .login {
    &__headline {
      .sm-p {
        display: none;
      }
    }
  }
  ion-tabs  {
    grid-template-areas:
    "content mainnav";
    grid-template-columns: 1fr 6.9rem;
  }
  .header {
    height: 2.4rem;
    position: relative;
    background: url("assets/img/evocare_grey.svg") top .4rem left 2rem / 8rem auto  no-repeat transparent;
    &--pos-abs {
      position: absolute;
      right: 0;
      background: url("assets/img/evocare_grey.svg") top .4rem right 2rem / 8rem auto no-repeat transparent;
    }
    &--pos-abs-no-bg {
      position: absolute;
      right: 0;
      background: transparent;
    }
    &--no-bg {
      background: none;
    }
  }
  ion-tab-bar {
    position: absolute;
    top: 0;
    z-index: 10000;
    width: 7rem;
    @include viewport-unit(height, 100vh);
    display: block;
    padding-left: 0;
    ion-tab-button {
      flex-direction: column;
      @include viewport-unit(height, 20vh);
      border-top: 0;
      border-left: 3px solid #fff;

      ion-label {
        font-weight: bold;
      }

      ion-icon {
        @include viewport-unit(height, 20vh);;
      }
    }
  }
  .modal-logout .modal-wrapper.sc-ion-modal-md.ion-overlay-wrapper {
    --width: 90%!important;
    --height: auto;
    max-width: 954px;
  }

  .modal-change-email .modal-wrapper {
    --width: 90%!important;
    --height: auto;
    max-width: 954px;
  }

  .modal-change-password .modal-wrapper.sc-ion-modal-md {
    --width: 90%;
    --height: auto;
    max-width: 750px;
  }

  .eventlist-overview, .consultation, .training-plan, .event-manual, .page, .group-therapy, .mydata, .page-diary, .vcpage, .help  {
    height: 100%!important;
    ion-grid {
      padding: 0;
    }
  }
  .scrollbar.scrollbar-height-header {
    height: calc(100% - 2.3rem)!important;
  }

  .scrollbar-height-custom {
    max-height: calc(100% - 2rem)!important;
  }
  .message-control {
    position: fixed;
    bottom: -12px;
    right: 0;
    left: 0;
  }
}
ion-tab-bar {
  grid-area: mainnav;
}
.ion-page {
  grid-area: content;
}


// helpers
.c-orange {
  color: #F47920;
}
.text-center {
  text-align: center;
}
.sm-text {
  font-size: .9rem!important;
}
.pos-abs {
  position: absolute;
}
.scroll-y {
  overflow-y: auto!important;
}
.no-scrollbars {
  overflow: hidden;
}

video {
  border-radius: 10px;
  overflow: hidden;
 }


@media screen and (max-height: 310px) {
    ion-modal {
      padding: 0!important;
    }
}
@supports (-webkit-touch-callout: none) {
  //.eventlist-overview, .consultation, .training-plan, .page, .group-therapy, .mydata, .page-diary, .vcpage, .help  {
  //  padding-bottom: 4.5rem;
  //}
  .ls-helper {
    letter-spacing: -.05rem;
  }
}


@media screen and (max-height: 400px){
  .header--fix-sp {
    position: absolute!important;
    right: 0;
    background: url("assets/img/evocare_grey.svg") top .4rem right .8rem / 8rem auto  no-repeat transparent;
  }
}

ion-accordion-group{
  ion-accordion {
    margin: 6px 0;
    [slot="header"] {
      --border-radius: 10px;
      ion-label {
        font-size: 1rem!important;
        font-weight: bolder;
      }
    }
    --background: red; //var(--ion-color-primary);
    --color: var(--ion-color-primary-contrast);
    .ion-accordion-toggle-icon {
      color: transparent;
      background: url(assets/img/icon_arrow_down.svg) center center/1.4rem no-repeat transparent;
    }
  }
}
.training-plan .scrollbar .ng-scrollbar-wrapper[verticalused="true"] .box-container--plan {
  padding: 0 .5rem 0 0;
}
.by-pos, .by-neg, .bw-pos, .bw-neg {
  .login {
    .container {
      &::after {
        content: none !important;
        background: none;
      }
    }
  }
}
.by-pos, .by-neg, .bw-pos, .bw-neg {
  .btn-ul {
    text-decoration: underline;
  }
}

